<template>
  <main>
    <div class="container">
      <div class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex justify-content-center align-items-center mb-4">
                <div
                  class="bg-primary style_level d-flex justify-content-center align-items-center"
                >
                  <h1 class="display-4 mb-0 text-white">
                    {{ currentStep + 1
                    }}<span style="font-size: 25px">/{{ totalSteps }}</span>
                  </h1>
                </div>
              </div>
              <div class="text-center mb-4">
                <h4 class="text-primary">Inscription d'une entreprise</h4>
                <!-- <h6 class="text-muted">Je suis une <a href="inscription_2.html" class="badge bg-success text-white">Entreprise</a></h6> -->
                <h6 class="text-muted">
                  Etape {{ currentStep + 1 }}/{{ steps[currentStep].title }}
                </h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div v-for="(step, index) in steps" :key="index">
                <component
                  v-model="entreprise"
                  v-if="index == currentStep"
                  :ref="`currentTab_${index}`"
                  :is="step.component"
                />
              </div>
              <di class="row">
                <div class="col-sm-12">
                  <!-- <b-alert variant="danger" :show="error">
                    {{
                      `Le numéro de téléphone '${this.entreprise.phone}' existe déjà. Essayez avec un autre numéro de téléphone.`
                    }}
                  </b-alert> -->
                </div>
              </di>
              <div v-if="!(!isLastStep && !estPeuOuPasInstruit)" class="row">
                <politique-confidentialite-dialog ref="policyDialog" />
                <div class="col-md-12 col-sm-12 border border-primary d-flex py-2">
                  <b-checkbox
                    name="policyAccepted"
                    class="form-contr me-3"
                    v-model="policyAccepted"
                    :binary="true"
                  >
                    <span class="ms-3">J'accepte la politique de confidentialité.</span>
                  </b-checkbox>
                  <a href="#" class="text-primary" @click.prevent.stop="showPolicyDialog"
                    >Voir la politique</a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isFirstStep"
                      @click.prevent="previousStep"
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                    >
                      Précédent
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isLastStep && !estPeuOuPasInstruit"
                      @click.prevent="nextStep"
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Suivant
                    </button>
                    <button
                      @click.prevent="submitForm"
                      v-else
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h6 class="text-muted text-center">J'ai un compte candidat <a href="connexion.html" class="text-primary">connectez-vous</a></h6> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Identification from "../../components/espace/entreprise/fiche/steps/Identification.vue";
import domicile from "../../components/espace/entreprise/fiche/steps/Domicile.vue";
import contacts from "../../components/espace/entreprise/fiche/steps/Contacts.vue";

import ActiviteEntreprise from "../../components/espace/entreprise/fiche/steps/ActiviteEntreprise.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapMutations } from "vuex";
import PolitiqueConfidentialiteDialog from "../../components/confidencialite/PolitiqueConfidentialiteDialog.vue";

export default {
  components: { PolitiqueConfidentialiteDialog },
  data() {
    return {
      policyAccepted: false,
      currentStep: 0,
      entreprise: {
        identification: {},
        domicile: {},
        contacts: {},
        activiteEntreprise: {},
        personnelFormation: {},
        besoinPersonnels: [],
      },
      error: false,
      steps: [
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Identification d'entreprise",
          component: Identification,
          hideFooter: true,
          hiddeHeader: false,
        },

        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Contacts",
          component: contacts,
          hideFooter: true,
          hiddeHeader: false,
        },

        {
          icon: "fa fa-home",
          name: "first",
          title: "Situation géographique",
          component: domicile,
        },

        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Activité de l'entreprise",
          component: ActiviteEntreprise,
          hideFooter: true,
          hiddeHeader: false,
        },
      ],
    };
  },
  created() {
    // this.entreprise = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.entreprise
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
  },
  watch: {
    entreprise() {
      localStorage.setItem("subscription", JSON.stringify(this.entreprise));
    },
    currentStep() {
      localStorage.setItem("currentStep", this.currentStep);
    },
    // "entreprise.phone": {
    //   handler() {
    //     this.error = false;
    //   },
    // },
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.totalSteps - 1;
    },
    hiddeHeader() {
      return this.steps[this.currentStep].hiddeHeader;
    },
    hideFooter() {
      return this.steps[this.currentStep].hideFooter;
    },
    estPeuOuPasInstruit() {
      return (
        this.entreprise.estInstruit != null &&
        (!this.entreprise.estInstruit ||
          (this.entreprise.estInstruit &&
            this.entreprise.niveauInstruction != null &&
            this.entreprise.niveauInstruction <= 10))
      );
    },
  },
  methods: {
    ...mapActions({
      registerEntreprise: "account/registerEmployeur",
    }),
    ...mapMutations({
      updateEntreprise: "entreprise/addEntreprise",
    }),
    resetForm() {
      localStorage.removeItem("subscription");
      localStorage.removeItem("currentStep");
    },
    showPolicyDialog() {
      this.$refs.policyDialog.show();
    },
    async submitForm() {
      if (!this.policyAccepted) {
        this.$toast.error(
          "Vous devez accepter la politique de confidentialité avant de continuer!",
          {
            position: "bottom-right",
            duration: 8000,
          }
        );
        return;
      }

      if (await this.validateStep(this.currentStep)) {
        this.registerEntreprise(this.entreprise).then((de) => {
          this.$router.push({
            name: "accounts.registration.entreprise.successful",
            query: { ppi: false, entreprise: true, hasEmail: this.hasValidEmail },
          });
        });
        // .catch(() => {
        //   this.$toast.error(`Erreur d'ajout d'offre.`, {
        //     position: "bottom-right",
        //     duration: 35000,
        //   });
        // });
      }
    },
    previousStep() {
      if (this.isFirstStep) return;
      this.currentStep--;
    },
    async nextStep() {
      if (this.isLastStep) return;
      if (await this.validateStep(this.currentStep)) {
        this.currentStep++;
      }
    },
    async validateStep(step) {
      this.updateEntreprise(this.entreprise);
      console.log(step);
      console.log(this.entreprise);
      console.log(this.$refs[`currentTab_${step}`][0]);
      let target = this.$refs[`currentTab_${step}`][0];
      return target.isCompleted();
      // return this.$refs[`currentTab_${step}`][0].do()
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
